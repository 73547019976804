import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';

import Logo from '@/components/logo/Logo';
import Scrollbar from '@/components/scrollbar/Scrollbar';
import { NAV } from '@/constants';
import { useResponsive } from '@/hooks/useResponsive';

import NavSection from './NavSection';
import NavToggleButton from './NavToggleButton';
import { useNavData } from './useNavData';

export default function NavVertical({
  openNav,
  onCloseNav,
}: {
  openNav: boolean;
  onCloseNav: VoidFunction;
}) {
  const { pathname } = useLocation();
  const theme = useTheme();
  const lgUp = useResponsive('up', 'lg');

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const NVSLogo =
    theme.palette.mode === 'dark' ? '/assets/nvs-logo-dark.png' : '/assets/nvs-logo.png';

  const navData = useNavData();

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          py: 2.5,
        }}
      >
        <Logo image={NVSLogo} />
      </Box>

      <NavSection data={navData} />
      <Box sx={{ flexGrow: 1 }} />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          py: 2.5,
        }}
      >
        <Logo image="/assets/pdm-logo-lg.svg" />
      </Box>
    </Scrollbar>
  );

  return (
    <Box
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_VERTICAL },
      }}
    >
      <NavToggleButton />

      {lgUp ? (
        <Stack
          sx={{
            height: 1,
            position: 'fixed',
            width: NAV.W_VERTICAL,
            borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          }}
        >
          {renderContent}
        </Stack>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          PaperProps={{
            sx: {
              width: NAV.W_VERTICAL,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
